import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  getSyncAlgoByUser, 
  syncAlgoByUser 
} from '../http'
import { AlgoSignalProcessMaster } from '../interfaces/AlgoSignalProcessMaster'

interface SyncAlgoState {
  syncAlgoData: AlgoSignalProcessMaster | null
  loading: boolean
  errors: any  
  syncSelectedAlgoData: any | null
  syncSelectedAlgoLoading: boolean
  syncSelectedAlgoErrors: any
}

const initialState: SyncAlgoState = {
    syncAlgoData: null,
    loading: false,
    errors: null,
    syncSelectedAlgoData: null,
    syncSelectedAlgoLoading: false,
    syncSelectedAlgoErrors: null
}

export const getSyncAlgoByUserID = createAsyncThunk(
  'syncAlgo/getSyncAlgo',
  async ({token, userId}: {token: any, userId: any}, thunkAPI) => {
    try {
      const response = await getSyncAlgoByUser(token, `strategy-sync/?userId=${userId}`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const syncSelectedAlgos = createAsyncThunk(
  "syncAlgo/syncSelected",
  async ({token, syncData}: {token: string, syncData: any[]}, thunkAPI) => {
    try {
      const response = await syncAlgoByUser(token, "strategy-sync/order", syncData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
);

// reducers -> reduce to a specific state -> changes state

export const syncAlgoSlice = createSlice({
  name: 'SyncAlgo',
  initialState,
  reducers: {
    setSyncAlgo: (state, action: PayloadAction<AlgoSignalProcessMaster>) => {
      state.syncAlgoData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSyncAlgoByUserID.pending, (state, action) => {
      state.loading = true
      state.syncAlgoData = null
      state.errors = null
    })
    builder.addCase(getSyncAlgoByUserID.fulfilled, (state, action) => {
      state.loading = false
      state.errors = null
      state.syncAlgoData = action.payload      
    })
    builder.addCase(getSyncAlgoByUserID.rejected, (state, action) => {
      state.loading = false
      state.syncAlgoData = null
      state.errors = action.payload
    })    
    builder.addCase(syncSelectedAlgos.pending, (state, action) => {
      state.syncSelectedAlgoLoading = true
      state.syncSelectedAlgoData = null
      state.syncSelectedAlgoErrors = null
    })
    builder.addCase(syncSelectedAlgos.fulfilled, (state, action) => {
      state.syncSelectedAlgoLoading = false
      state.syncSelectedAlgoErrors = null
      state.syncSelectedAlgoData = action.payload      
    })
    builder.addCase(syncSelectedAlgos.rejected, (state, action) => {
      state.syncSelectedAlgoLoading = false
      state.syncSelectedAlgoData = null
      state.syncSelectedAlgoErrors = action.payload
    })
  },
})

export default syncAlgoSlice.reducer
export const {setSyncAlgo} = syncAlgoSlice.actions
