import React from 'react';
import { format } from 'date-fns'
import {TableCell} from '@mui/material';

const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return dateString ? format(date, "dd/MM/yyyy") : '';
  }

export const countryCode = [
    { countryCode: 'AF', label: 'Afghanistan', phone: '+93' },
    {
      countryCode: 'AU',
      label: 'Australia',
      phone: '+61',
      suggested: true,
    },
    { countryCode: 'BR', label: 'Brazil', phone: '+55' },
    { countryCode: 'BT', label: 'Bhutan', phone: '+975' },
    {
      countryCode: 'CA',
      label: 'Canada',
      phone: '+1',
      suggested: true,
    },
    { countryCode: 'GB', label: 'United Kingdom', phone: '+44' },
    { countryCode: 'ID', label: 'Indonesia', phone: '+62' },
    { countryCode: 'IE', label: 'Ireland', phone: '+353' },
    { countryCode: 'IL', label: 'Israel', phone: '+972' },    
    { countryCode: 'IN', label: 'India', phone: '+91' },
    { countryCode: 'NP', label: 'Nepal', phone: '+977' },
    {
      countryCode: 'US',
      label: 'United States',
      phone: '+1',
      suggested: true,
    },
    { countryCode: 'LK', label: 'Sri Lanka', phone: '+94' },
]

export const tableHeaderForRewardPointCategory: any = [
    {
        Header: 'Category',
        accessor: 'rewardPointCategory',
        align: 'left',
    },
    {
        Header: 'Description',
        accessor: 'description',
        align: 'left',
    }
]

export const tableHeaderForRewardPointPlan: any = [
    {
        Header: 'Plan',
        accessor: 'rewardPlan',
        align: 'left',        
    },
    {
        Header: 'Category',
        accessor: 'rewardPointCategory',
        align: 'left',
    },
    {
        Header: 'Type',
        accessor: 'rewardType',
        align: 'left',
    },
    {
        Header: 'Points',
        accessor: 'pointsOnReward',
        align: 'right',
        format: (value: string) => `${value}-T`
    },
    {
        Header: 'Duration',
        accessor: 'displayDuration',
        align: 'left',
    },
    {
        Header: 'Start Date',
        accessor: 'displayRewardPlanStartDate',
        align: 'left',        
    },
    {
        Header: 'End Date',
        accessor: 'displayRewardPlanEndDate',
        align: 'left',        
    },
    {
        Header: 'Status',
        accessor: 'status',
        align: 'left',
    }
]

export const tableHeaderForRewardPointValue: any = [
    {
        Header: 'Reward Points',
        accessor: 'formatedRewardPoints',
        align: 'right',
        width: '15%'     
    },
    {
        Header: 'Reward Point Value',
        accessor: 'currencyValueWithCurrency',
        align: 'right',
        width: '20%'
    },
    {
        Header: 'From Date',
        accessor: 'displayFromDate',
        align: 'left',
        width: '20%'
    },
    {
        Header: 'To Date',
        accessor: 'displayToDate',
        align: 'left',
        width: '20%'
    },
    {
        Header: 'Status',
        accessor: 'displayStatus',
        align: 'center',
        width: '20%'
    }    
]

export const tableHeaderForDiscountCoupon: any = [
    {
        Header: 'Coupon Code',
        accessor: 'couponCode',
        align: 'left',       
    },
    {
        Header: 'Currancy Code',
        accessor: 'currancyCode',
        align: 'left',
    },
    {
        Header: 'Direct Discount',
        accessor: 'directDiscount',
        align: 'left',
    },
    {
        Header: 'Max Invoice Amount',
        accessor: 'maxInvoiceAmount',
        align: 'right',        
    },
    {
        Header: 'Min Invoice Amount',
        accessor: 'minInvoiceAmount',
        align: 'left',
    },
    {
        Header: '% Discount',
        accessor: 'percentageDiscount',
        align: 'left',     
    },
    {
        Header: 'Status',
        accessor: 'status',
        align: 'center',        
    },
    {
        Header: 'Status Description',
        accessor: 'statusDescription',
        align: 'left',
    },
    {
        Header: 'Validity Count',
        accessor: 'validityCount',
        align: 'left',
    },
    {
        Header: 'Validity Running Counter',
        accessor: 'validityRunningCounter',
        align: 'left',
    },
    {
        Header: 'From Date',
        accessor: 'fromDate',
        align: 'left',
        // Cell: ({ value }: { value: string }) => {<TableCell>value</TableCell>},
        // format: (value: string) => formatDate(value)
    },
    {
        Header: 'To Date',
        accessor: 'toDate',
        align: 'left',
    }
]

export const tableHeaderForDiscountCouponDetail: any = [
    {
        Header: 'invoice Id',
        accessor: 'invoiceId',
        align: 'left',        
    },
    {
        Header: 'Status',
        accessor: 'status',
        align: 'left',
    },
    {
        Header: 'Apply Date',
        accessor: 'applyDate',
        align: 'left',
    }
]

export const tableHeaderForSubscriptionPlanMaster: any = [
    {
        Header: 'Plan Name',
        accessor: 'planName',
        align: 'left',      
    },
    {
        Header: 'Plan Price',
        accessor: 'planPricingCurrency',
        align: 'left',
    },
    {
        Header: 'No Of Strategy Deploy',
        accessor: 'noOfStrategyDeploy',
        align: 'left',
    },
    {
        Header: 'No Of Private Strategy',
        accessor: 'noOfPrivateStrategy',
        align: 'left',
    },
    {
        Header: 'No Of Public Strategy',
        accessor: 'noOfPublicStrategy',
        align: 'left',
    },
    {
        Header: 'Exchange Data Feed',
        accessor: 'exchangeDataFeed',
        align: 'left',
    },
    {
        Header: 'Continous Checking Frequency',
        accessor: 'continousCheckingFrequency',
        align: 'left',
    },
    {
        Header: 'Complimentary Test Allowed Per Month',
        accessor: 'complimentaryTestAllowedPerMonth',
        align: 'left',
    }
]

export const tableHeaderForClientExchange: any = [    
    {
        Header: 'Color Code',
        accessor: 'colorCode',
        align: 'center',
        width: '3%'
    },
    {
        Header: 'Exchange',
        accessor: 'exchangeName',
        align: 'left',    
        width: '10%'    
    },
    {
        Header: 'Tag',
        accessor: 'accountTag',
        align: 'left',
        width: '15%'
    },
    {
        Header: 'App Id',
        accessor: 'appId',
        align: 'left',
        width: '17%'
    },
    {
        Header: 'Link For Login',
        accessor: 'url',
        align: 'center',
        width: '15%'
    },
    {
        Header: 'Last Login',
        accessor: 'lastLogin',
        align: 'left',
        width: '15%'
    },
    {
        Header: 'Remaining Time',
        accessor: 'remainingTime',
        align: 'left',
        width: '15%'
    },
    {
        Header: 'Status',
        accessor: 'status',
        align: 'center',
        width: '10%'
    }
]

export const tableHeaderForExchange: any = [    
    {
        Header: 'Color Code',
        accessor: 'colorCode',
        align: 'center',
        width: '3%'
    },
    {
        Header: 'Exchange',
        accessor: 'exchangeName',
        align: 'left',    
        width: '15%'    
    },
    {
        Header: 'Display Name',
        accessor: 'displayableExchangeName',
        align: 'left',    
        width: '15%'    
    },
    {
        Header: 'Callback URL',
        accessor: 'integrationCallbackURL',
        align: 'left',    
        width: '30%'    
    },
    {
        Header: 'Login URL',
        accessor: 'exchangeLoginURL',
        align: 'left',    
        width: '30%'    
    }
]

export const tableHeaderForUser: any = [
    {
        Header: 'Name',
        accessor: 'displayName',
        align: 'left',        
    },
    // {
    //     Header: 'Date of Birth',
    //     accessor: 'birthDate',
    //     align: 'left',
    // },
    // {
    //     Header: 'Gender',
    //     accessor: 'gender',
    //     align: 'left',
    // },
    {
        Header: 'Email ID',
        accessor: 'email',
        align: 'left',
        format: (value: string) => `${value}-T`
    },
    {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        align: 'left',
    }
]

export const tableHeaderForIndicies: any = [        
    {
        Header: 'Code',
        accessor: 'code',
        align: 'left',    
        width: '10%'    
    },
    {
        Header: 'Name',
        accessor: 'name',
        align: 'left',    
        width: '15%'    
    },    
    {
        Header: 'Category',
        accessor: 'indicesCategory',
        align: 'left',    
        width: '15%'    
    },
    {
        Header: 'Sub Category',
        accessor: 'subCategory',
        align: 'left',    
        width: '10%'    
    },
    {
        Header: 'Source Type',
        accessor: 'inputSourceType',
        align: 'left',    
        width: '15%'    
    },
    {
        Header: 'Source',
        accessor: 'inputSource',
        align: 'left',    
        width: '15%'    
    },
    {
        Header: 'Source URL',
        accessor: 'inputSourceUrl',
        align: 'left',    
        width: '15%'    
    },    
]

export const exchangeDocList: any = [
    {
        exchange : "Upstox",
        url: "https://drive.google.com/file/d/1JmbWvXI2hZzNyQk3M6KNkvePmcPtORPX/view?usp=sharing"
    },
    {
        exchange : "Five-Paisa",
        url: "https://drive.google.com/file/d/1V087KnewK4sdmbr9-LRb3n64etxNl7l-/view?usp=sharing"
    },
    {
        exchange : "FlatTrade",
        url: "https://drive.google.com/file/d/13eIsPh2vRQOUQI5IhwDTHBF11NvBEpz3/view?usp=drive_link"
    },
    {
        exchange : "Paytm-Money",
        url: "https://drive.google.com/file/d/1INzrGTf-VbB7WxCVtcpXeqMueea3NUQI/view?usp=sharing"
    },
    {
        exchange : "Zerodha-Kite",
        url: "https://drive.google.com/file/d/1xkvnp2IdmkJWsKHzig6ueXgzEO1jo3h0/view?usp=sharing"
    },
    {
        exchange : "Angel-One",
        url: "https://drive.google.com/file/d/1tPDjVzyqY6KSUM9ThuZnghV_hd73U-y_/view?usp=sharing"
    },
    {
        exchange : "Fyers",
        url: "https://drive.google.com/file/d/1vzPwxdeIq4M-0FJYOuLi0tElhPX_ekIW/view?usp=sharing"
    },
    {
        exchange : "Icici-Direct",
        url: "https://drive.google.com/file/d/11Fe83hSRfmeN95VoF2xdHmdYkmpk3UH1/view?usp=sharing"
    },
    {
        exchange : "MotilalOswal",
        url: "https://drive.google.com/file/d/1iJ_4E__MWlflhAPbL5nDxiCqXrxFR4bm/view?usp=sharing"
    }
]