import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
// const Store = createStore(RootReducer, composeWithDevTools(applyMiddleware(thunk)));

import referralReducer from './app/modules/reducers/referralSlice'
import strategyReducer from '../src/app/modules/reducers/strategySlice'
import brokerReducer from './app/modules/reducers/clientExchangeSlice'
import userLiveStrategyReducer from '../src/app/modules/reducers/userLiveStrategySlice'
import userReducer from '../src/app/modules/reducers/userSlice'
import algoSignalProcessingReducer from '../src/app/modules/reducers/algoSignalProcessingSlice'
import exchangeReducer from './app/modules/reducers/exchangeSlice'
import paymentOrderReducer from './app/modules/reducers/paymentOrderSlice'
import rewardPointCategoryReducer from './app/modules/reducers/rewardPointCategorySlice'
import rewardPointPlanReducer from './app/modules/reducers/rewardPointPlanSlice'
import rewardPointValueReducer from './app/modules/reducers/rewardPointValueSlice'
import multiUserExchangeReducer from './app/modules/reducers/multiUserExchangeSlice'
import instrumentMasterSlice from './app/modules/reducers/instrumentMasterSlice'
import subscriptionPlanSlice from './app/modules/reducers/subscriptionPlanSlice'
import discountCouponSlice from './app/modules/reducers/discountCouponSlice'
import discountCouponDetailSlice from './app/modules/reducers/discountCouponDetailSlice'
import profileImageSlice from './app/modules/reducers/profileImageSlice'
import stockAnalysisSlice from './app/modules/reducers/stockAnalysisSlice'
import algoActivitySlice from './app/modules/reducers/algoActivitySlice'
import userActivitySlice from './app/modules/reducers/userActivitySlice'
import indiciesSlice from './app/modules/reducers/indiciesSlice'
import syncAlgoSlice from './app/modules/reducers/syncAlgoSlice'

export const store = configureStore({
  reducer: {
    referral: referralReducer,
    strategy: strategyReducer,
    clientExchange: brokerReducer,
    userLiveStrategy: userLiveStrategyReducer,
    user: userReducer,
    algoSignalProcessing: algoSignalProcessingReducer,
    exchange: exchangeReducer,
    paymentOrder: paymentOrderReducer,
    rewardPointCategory: rewardPointCategoryReducer,
    rewardPointPlan: rewardPointPlanReducer,
    rewardPointValue: rewardPointValueReducer,
    multiUserExchange: multiUserExchangeReducer,
    instrument: instrumentMasterSlice,
    subscriptionPlan: subscriptionPlanSlice,
    discountCoupon: discountCouponSlice,
    discountCouponDetail: discountCouponDetailSlice,
    profileImage: profileImageSlice,
    stockAnalysis: stockAnalysisSlice,
    algoActivity: algoActivitySlice,
    userActivity: userActivitySlice,
    indicies: indiciesSlice,
    syncAlgo: syncAlgoSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AddDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AddDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
