import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, AppBar, Button, Container, Toolbar, Avatar, Typography, IconButton, Snackbar, Alert, TextField } from '@mui/material';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { KTSVG } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_metronic/partials/content/dropdown/Dropdown1';
import { getUserActivity } from '../reducers/userActivitySlice';
import { useAppDispatch, useAppSelector } from '../../../Store';
import { useAuth } from '../auth';
import { Data } from '../interfaces/UserActivity';
import moment from 'moment';


export type IDataProps = {
    open: boolean,
    dialogClose: () => void
    className: string
}

function AllUserActivityDialog(props: IDataProps) {
    const { open, dialogClose, className } = props;
    const dispatch = useAppDispatch();
    const token = useAuth().auth?.token;
    const { userActivityData, loading } = useAppSelector((state) => state.userActivity);
    const [userData, setUserData] = useState<Data[]>();

    const body = {
        token: token,
    }

    useEffect(() => {
        dispatch(getUserActivity(body))
    }, [dispatch]);

    useEffect(() => {
        setUserData(userActivityData?.modal?.data)
    }, [userActivityData]);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='sm'
            keepMounted
        >
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    bgcolor: '#1C202E'
                }}
            >

                <Container maxWidth='xl'>
                    <Toolbar variant="dense" disableGutters sx={{
                        minHeight: '10px'
                    }}>
                        <Avatar variant='rounded' src={toAbsoluteUrl('/media/logos/Til_logo.jpg')} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: 30, height: 30 }} />
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{
                                flexGrow: 1,
                                textAlign: 'center'
                            }}
                        >
                            All User Activities
                        </Typography>
                        <IconButton onClick={props.dialogClose}><CloseSharpIcon fontSize='medium' sx={{ color: 'white' }} /> </IconButton>
                    </Toolbar>
                </Container>
            </AppBar>
            <DialogContent>
                <div className={`card ${className}`}>
                    {/* begin::Header */}
                    <div className='card-header align-items-center border-0 mt-4'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='fw-bold mb-2 text-dark'>User Activities</span>
                            <span className='text-muted fw-semibold fs-7'>{userData?.length ? userData?.length : 0} User Activities</span>
                        </h3>
                        <div className='card-toolbar'>
                            {/* begin::Menu */}
                            <button
                                type='button'
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                            </button>
                            <Dropdown1 />
                            {/* end::Menu */}
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body pt-5' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {/* begin::Timeline */}
                        {userData && userData.length > 0 ? (
                            <div className='timeline-label'>
                                {userData.map((item, index) => (
                                    <div className='timeline-item' key={index}>
                                        <div className='timeline-label fw-bold text-gray-800 small' style={{ fontSize: '9px' }}>
                                            {/* {moment(item.dateTime).format('YYYY-MM-DD HH:mm')} */}
                                            {moment(item.dateTime).format('DDMMMYY')}
                                            <br />
                                            {moment(item.dateTime).format('HH:mm')}
                                        </div>
                                        <div className='timeline-badge'>
                                            <i className='fa fa-genderless text-warning fs-1'></i>
                                        </div>
                                        <div className='timeline-content fw-mormal text-muted ps-3'>
                                            {item.messages}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) :
                            (
                                <div className='text-center text-muted fw-bold fs-3 mt-12'>No User Activity Performed</div>
                            )}
                        {/* end::Timeline */}
                    </div>
                    {/* end: Card Body */}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AllUserActivityDialog;
